'use strict';
app.factory('msgService', function ($rootScope, $http, $filter, $q)
{
    let msgServiceFactory = {};
    
    msgServiceFactory.displayMessage = function (msgcode, $scope, parameter)
    {
        var msgData = $scope.msg[msgcode][$scope.langKey];

        if (typeof parameter !== "undefined")
        {
            $scope.message = $filter('stringFormat')($scope.msg[msgcode][$scope.langKey].MsgBody, Array.isArray(parameter) ? parameter : [parameter]);
        }
        else
        {
            $scope.message = msgData.MsgBody;
        }

        $scope.messagetype = msgData.MsgType;
        $scope.messagetitle = msgData.MsgKey;
        $scope.messageicon = msgData.MsgIcon;
        //INFORMATION: 1,
        //WARNING: 2,
        //ERROR: 3,
        //QUESTION: 4
        if (msgData.MsgIcon == $rootScope.MsgIcon.INFORMATION)
        {
            $scope.messageicon = { 'background-position': '0px -687px' };
        }
        else if (msgData.MsgIcon == $rootScope.MsgIcon.WARNING)
        {
            $scope.messageicon = { 'background-position': '0px -730px' };
        }
        else if (msgData.MsgIcon == $rootScope.MsgIcon.ERROR)
        {
            $scope.messageicon = { 'background-position': '0px -773px' };
        }
        else if (msgData.MsgIcon == $rootScope.MsgIcon.QUESTION)
        {
            $scope.messageicon = { 'background-position': '0px -816px' };
        }

        //check body length
        var estimatedHeight = (5 * 10) + 120;

        var dialog = $scope.winMsg;
        dialog.setOptions({ width: 400, height: estimatedHeight, resizable: false });
        dialog.title(msgData.MsgHdr);

        $scope.winMsg.center().open();
        $scope.winMsgVisible = true;
        if ($scope.$root.$$phase != '$apply' && $scope.$root.$$phase != '$digest')
        {
            $scope.$apply();
        }
    };

    msgServiceFactory.displayNoRecords = function (e)
    {
        var colspan = e.sender.thead.find("th:visible").length,
            emptyRow = '<tr role="row"><td colspan="' + colspan + '" role="gridcell"> No records </td></tr>';
        e.sender.tbody.parent().width(e.sender.thead.width()).end().html(emptyRow);
    };

    msgServiceFactory.displayCustomMessage = function (msg, $scope)
    {
        var msgData = msg;

        $scope.messagetype = msgData.MsgType;
        $scope.messagetitle = msgData.MsgKey;
        $scope.messageicon = msgData.MsgIcon;

        if (msgData.MsgIcon == $rootScope.MsgIcon.INFORMATION)
        {
            $scope.messageicon = { 'background-position': '0px -687px' };
        }
        else if (msgData.MsgIcon == $rootScope.MsgIcon.WARNING)
        {
            $scope.messageicon = { 'background-position': '0px -730px' };
        }
        else if (msgData.MsgIcon == $rootScope.MsgIcon.ERROR)
        {
            $scope.messageicon = { 'background-position': '0px -773px' };
        }
        else if (msgData.MsgIcon == $rootScope.MsgIcon.QUESTION)
        {
            $scope.messageicon = { 'background-position': '0px -816px' };
        }

        //check body length
        var estimatedHeight = (5 * 10) + 120;

        var dialog = $scope.winMsg;
        dialog.setOptions({ width: 400, height: estimatedHeight, resizable: false });
        dialog.title(msgData.MsgHdr);
        $scope.message = msgData.MsgBody;

        $scope.winMsg.center().open();
        $scope.winMsgVisible = true;
        if ($scope.$root.$$phase != '$apply' && $scope.$root.$$phase != '$digest')
        {
            $scope.$apply();
        }
    };

    msgServiceFactory.displayCustomMessageAndButtons = function (msg, $scope)
    {
        const { MsgType, MsgIcon, MsgHdr, MsgBody, TotalRecords } = msg;
        $scope.messagetype = MsgType;

        if (MsgIcon == $rootScope.MsgIcon.INFORMATION)
        {
            $scope.messageicon = { 'background-position': '0px -687px' };
        }
        else if (MsgIcon == $rootScope.MsgIcon.WARNING)
        {
            $scope.messageicon = { 'background-position': '0px -730px' };
        }
        else if (MsgIcon == $rootScope.MsgIcon.ERROR)
        {
            $scope.messageicon = { 'background-position': '0px -773px' };
        }
        else if (MsgIcon == $rootScope.MsgIcon.QUESTION)
        {
            $scope.messageicon = { 'background-position': '0px -816px' };
        }

        var estimatedHeight = (TotalRecords * 20) + 160;

        var dialog = $scope.winMsg;
        dialog.setOptions({ width: 400, height: estimatedHeight, resizable: false });
        dialog.title(MsgHdr);
        $scope.message = MsgBody;

        $scope.winMsg.center().open();
        $scope.winMsgVisible = true;
        if ($scope.$root.$$phase != '$apply' && $scope.$root.$$phase != '$digest')
        {
            $scope.$apply();
        }
    }

    msgServiceFactory.displayAnnualTaxCustomMessage = function (msg, $scope)
    {
        var msgData = msg;

        $scope.messagetype = msgData.MsgType;
        $scope.messagetitle = msgData.MsgKey;
        $scope.messageicon = msgData.MsgIcon;

        if (msgData.MsgIcon == $rootScope.MsgIcon.INFORMATION)
        {
            $scope.messageicon = { 'background-position': '0px -687px' };
        }
        else if (msgData.MsgIcon == $rootScope.MsgIcon.WARNING)
        {
            $scope.messageicon = { 'background-position': '0px -730px' };
        }
        else if (msgData.MsgIcon == $rootScope.MsgIcon.ERROR)
        {
            $scope.messageicon = { 'background-position': '0px -773px' };
        }
        else if (msgData.MsgIcon == $rootScope.MsgIcon.QUESTION)
        {
            $scope.messageicon = { 'background-position': '0px -816px' };
        }

        var dialog = $scope.winMsg;
        dialog.setOptions({ width: 400, height: 600, resizable: false, scrollable: true });
        dialog.title(msgData.MsgHdr);
        $scope.message = msgData.MsgBody;
        document.getElementById("msgBody").setAttribute('style', 'text-align:left;!important');
        $scope.winMsg.center().open();
        $scope.winMsgVisible = true;
        if ($scope.$root.$$phase != '$apply' && $scope.$root.$$phase != '$digest')
        {
            $scope.$apply();
        }
    };

    msgServiceFactory.addLoading = function (win)
    {
        var loadingContext = '<div class="k-loading-mask" style="top: 0px;width: 100%;height: 100%;left: 0px;"><span class="k-loading-text">Loading...</span><div class="k-loading-color"></div></div>';
        win.element.closest(".k-window").append($(loadingContext));
    };

    msgServiceFactory.removeLoading = function (win)
    {
        win.element.closest(".k-window").find(".k-loading-mask").remove();
    };

    msgServiceFactory.closeMessage = function (e, $scope)
    {
        if (e == 'yes')
        {
            if ($scope.deferred != null)
                $scope.deferred.resolve("YES");
        }
        else if (e == 'cancel')
        {
            if ($scope.deferred != null)
                $scope.deferred.resolve("CANCEL");
        }
        else
        {
            if ($scope.deferred != null)
            {
                $scope.deferred.reject("NO");
            }
        }

        $scope.winMsg.close();
    };

    msgServiceFactory.closeCustomQCMessage = function (e, $scope){
        if(e == "Merge"){
            if ($scope.deferred != null)
                $scope.deferred.resolve("Merge");
        }
        else if(e == "Overwrite"){
            if ($scope.deferred != null)
                $scope.deferred.resolve("Overwrite");
        }
        else if(e == "Cancel"){
            if ($scope.deferred != null)
                $scope.deferred.reject("NO");
        }
        $scope.winMsg.close();
    }

    msgServiceFactory.onCloseMsg = function ($scope)
    {
        if ($scope.deferred != null)
            $scope.deferred.resolve("CLOSE");
    }

    msgServiceFactory.loadMsgTemplate = function ($scope)
    {
        $scope.msgtemplate = "";
        $http.get("../../components/views/messagebox.html", {}).success(function (html)
        {
            $scope.msgtemplate = (html);
        });
    };

    //overwrite kendo grid pager hardcoded message with label to cater for different language
    msgServiceFactory.getGridPagerMsg = function ($scope, isUqf)
    {
        return {
            //isUqf = true: "{0} - {1} of {2} items"; isUqf = false: "A total of {2} items"
            display: (isUqf === true) ? $scope.msg[229][$scope.langKey].MsgBody : $scope.msg[52][$scope.langKey].MsgBody,
            allPages: $scope.label[514][$scope.langKey].LabelDesc, //"All"
            empty: $scope.label[3546][$scope.langKey].LabelDesc, //"No items to display",
            page: $scope.label[1183][$scope.langKey].LabelDesc, //"Page",
            of: $scope.msg[211][$scope.langKey].MsgBody,  //"of {0}",
            itemsPerPage: $scope.label[3547][$scope.langKey].LabelDesc, //"items per page",
            first: $scope.label[3548][$scope.langKey].LabelDesc, //"Go to the first page",
            previous: $scope.label[3549][$scope.langKey].LabelDesc, //"Go to the previous page",
            next: $scope.label[3550][$scope.langKey].LabelDesc, //"Go to the next page",
            last: $scope.label[3551][$scope.langKey].LabelDesc, //"Go to the last page",
            refresh: $scope.label[1016][$scope.langKey].LabelDesc, //"Refresh"
            morePages: $scope.label[3552][$scope.langKey].LabelDesc, //"More pages"
        };
    };

    //grid template for no record or result
    //isDisplayForm = true: for uqf display form to display info message when click query; isDisplayForm = false: for form which does not have query such as master form
    //isViewOnly = true: display form is for view only, cannot create new record on the form itself (will show different message)
    msgServiceFactory.getNoRecordGridTemplateMsg = function ($scope, isDisplayForm, isViewOnly, skipNoRcdAtBottom)
    {
        return {
            template: function ()
            {
                if (isDisplayForm)
                {
                    if (skipNoRcdAtBottom !== true)
                    {
                        if ($scope.querying === true)
                        {
                            $scope.msgHeaderIcon = angular.copy($rootScope.MsgIcon.INFORMATION);
                            $scope.msgHeader = (isViewOnly === true) ? $scope.msg[231][$scope.langKey].MsgBody : $scope.msg[230][$scope.langKey].MsgBody;
                        }
                    }
                }
                return '<div class="k-grid-norecords-template" font-size="12px">' + $scope.label['3555'][$scope.langKey].LabelDesc + '</div>'
            }
        };
    };

    //460: Are you sure you want to void the record?
    msgServiceFactory.promptMessage = async (msgKey, $scope) => {
        $scope.deferred = $q.defer();
        const promise = $scope.deferred.promise;
        msgServiceFactory.displayMessage(msgKey, $scope);
        return await promise.catch(e => { });
    }

    return msgServiceFactory;
});